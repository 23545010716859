import $ from 'jquery' // important: case sensitive.

import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Image from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faVideo,
  faCode,
  faFilePdf,
  faQuoteRight
} from '@fortawesome/free-solid-svg-icons'

require('./publications.css')
require('./bttn.css')

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const images = {};
    for (let image in data.images.edges) {
      images[data.images.edges[image].node.relativePath] = data.images.edges[image].node
    }

    function copy_to_clipboard(e, txt) {
      var dummy = $('<input>').val(txt).appendTo('body').select()
      document.execCommand('copy');
      dummy.hide();
      e.persist();
      $(e.target).html('copied');

      setTimeout(function () {
        $(e.target).html('bibtex');
      }, 600);
    }

    function jsxJoin(array, str) {
      return array.length > 0
        ? array.reduce((result, item) => <>{result}{str}{item}</>)
        : null;
    }

    let year = null;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <h3>Talks</h3>
        <div>
          <div className={'title'} ><a target='__blank__' href="https://www.dropbox.com/scl/fi/plyd78b9zz4tobng8y4zb/seminar_implicit_representaitons.pdf?rlkey=rnomvu0v4liv4899x8uakmukj&st=tq4i3ono&dl=0">Implicit Representations Seminar</a></div>
          <div className={'description'}>University of Oxford, OATML seminar - June 2022</div>
        </div>
        <div>
          <div className={'title'} ><a target='__blank__' href="https://www.dropbox.com/s/tz8hv39zvh77xwb/causal_experimental_design.pdf?dl=0">Interventions Where and How? Causal Experimental Design at Scale</a></div>
          <div className={'description'}>Wellcome Centre of Human Neuroimaging, UCL - April 2022</div>
        </div>
        <div>
          <div className={'title'} ><a target='__blank__' href="https://drive.google.com/file/d/1FT4lrwEjejqgU8pj955foqM38YBciN0f/view?usp=sharing">Active Inference Seminar</a></div>
          <div className={'description'}>University of Oxford, OATML seminar - Feb 2021</div>
        </div>
        <div>
          <div className={'title'} ><a target='__blank__' href="https://drive.google.com/file/d/1p6l0Ya9FJQKZdXOnulfOx7JEtsXAAXYe/view?usp=sharing">Robust Autonomous Driving</a></div>
          <div className={'description'}>Google-Oxford workshop, November 2020 <br/> Qualcomm, Research talk, November 2020</div>
        </div>
        <div>
          <div className={'title'} ><a target='__blank__' href="https://drive.google.com/file/d/1dWBu_Nro3a3Jwdm8De46tDPPBMDjttsW/view?usp=sharing">Artificial Intelligence in Art &amp; Design</a></div>
          <div className={'description'}>Bartlett School of Architecture, UCL, London - Feb 2019 <br /> Department of Fine Art, U. of Ioannina, Greece - Nov 2019</div>
        </div>

        <div style={{ marginTop: rhythm(1.5) }} />
        <h3>Publications / Technical Reports</h3>
        <div style={{ marginTop: rhythm(1) }} />

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const description = node.frontmatter.description;
          const conference = node.frontmatter.conference;

          let codeButton = '';
          let urls = [];
          urls.push(<span><FontAwesomeIcon icon={faFilePdf} size="1x" /> <a href={node.frontmatter.pdf} target={'_blank'}>PDF</a></span>);
          urls.push(<span><FontAwesomeIcon icon={faQuoteRight} size="1x" />  <a onClick={e => copy_to_clipboard(e, node.frontmatter.bibtex)}>bibtex</a></span>);
          if (node.frontmatter.code) {
            codeButton = urls.push(<span><FontAwesomeIcon icon={faCode} size="1x" /> <a href={node.frontmatter.code} target={'_blank'}>code</a></span>)
          }

          if (node.frontmatter.video) {
            codeButton = urls.push(<span><FontAwesomeIcon icon={faVideo} size="1x" /> <a href={node.frontmatter.video} target={'_blank'}>video</a></span>)
          }

          let year_tag = '';
          if (year != node.frontmatter.date.split(', ')[1]) {
            year = node.frontmatter.date.split(', ')[1];
            year_tag = <h5 className={'year'}>{year}</h5>
          }

          return (
            <span>
              {year_tag}
              <div key={node.fields.slug} style={{
                display: "flex",
                paddingBottom: rhythm(1)

              }}>
                <div className={'paper_image'}>
                  {console.log(data.images.edges)}
                  <Image fixed={images[node.frontmatter.featured_image.relativePath].childImageSharp.fixed} />
                </div>
                <div className={'paper'}>
                  <div className={'title'} ><a href={node.frontmatter.pdf} target={'_blank'}>{title}</a></div>
                  <div className={'authors'} style={{ paddingTop: 0, paddingBottom: '5px' }} dangerouslySetInnerHTML={{ __html: node.frontmatter.authors.replace("Panagiotis Tigas", "<b>Panagiotis Tigas</b>") }} />
                  <div className={'conference'} >{conference}</div>
                  {/* <div className={'description'} >{description}</div> */}
                  <div className={'urls'}>{jsxJoin(urls, " | ")}</div>
                </div>
              </div>
            </span>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter:{frontmatter:{category:{eq: "publications"}}}
    ) {      edges {
        node {
          excerpt
          body
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            authors
            pdf
            title
            conference
            code
            video
            bibtex
            featured_image {publicURL, relativePath}
            description
          }
        }
      }
    }
    images: allFile(filter: {
      absolutePath: { regex: "/content/publications/(.*)/(.*).[gif|png|jpg]/" }
    }) {
      edges {
        node {
          relativePath
          childImageSharp  {
            sizes(maxWidth: 140, cropFocus: CENTER) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
            fixed(height:140, width: 140){
              src
              aspectRatio
              srcSet
              height
              width
            }
          }
        }
      }
    }
  }
`
